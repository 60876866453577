// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SoldToAccountsQueryVariables = Types.Exact<{
  soldTos?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  requestType?: Types.InputMaybe<Types.RequestType>;
  cartType?: Types.InputMaybe<Types.CartRequestAction>;
  requestSource?: Types.InputMaybe<Types.RequestSource>;
  addressBook?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SoldToAccountsQuery = { __typename?: 'Query', getSoldToAccounts: Array<{ __typename?: 'PartnerAccount', type: string, organizationName: string, attentionTo: string, partnerNumber: string, accountNumber: string, department: string, buildingRoom: string, streetAddresses: Array<string>, city: string, state: string, country: string, postalCode: string, isDefault: boolean, salesOrg: string }> };

export type SoldToAccountDetailFragment = { __typename?: 'PartnerAccount', type: string, organizationName: string, attentionTo: string, partnerNumber: string, accountNumber: string, department: string, buildingRoom: string, streetAddresses: Array<string>, city: string, state: string, country: string, postalCode: string, isDefault: boolean, salesOrg: string };

export const SoldToAccountDetailFragmentDoc = gql`
    fragment SoldToAccountDetail on PartnerAccount {
  type
  organizationName
  attentionTo
  partnerNumber
  accountNumber
  department
  buildingRoom
  streetAddresses
  city
  state
  country
  postalCode
  isDefault
  salesOrg
}
    `;
export const SoldToAccountsDocument = gql`
    query SoldToAccounts($soldTos: [String!] = [], $requestType: RequestType = SOLDTO, $cartType: CartRequestAction, $requestSource: RequestSource, $addressBook: Boolean) {
  getSoldToAccounts(soldTos: $soldTos, requestType: $requestType, cartType: $cartType, requestSource: $requestSource, addressBook: $addressBook) {
    ...SoldToAccountDetail
  }
}
    ${SoldToAccountDetailFragmentDoc}`;
export type SoldToAccountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SoldToAccountsQuery, SoldToAccountsQueryVariables>, 'query'>;

    export const SoldToAccountsComponent = (props: SoldToAccountsComponentProps) => (
      <ApolloReactComponents.Query<SoldToAccountsQuery, SoldToAccountsQueryVariables> query={SoldToAccountsDocument} {...props} />
    );
    

/**
 * __useSoldToAccountsQuery__
 *
 * To run a query within a React component, call `useSoldToAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoldToAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoldToAccountsQuery({
 *   variables: {
 *      soldTos: // value for 'soldTos'
 *      requestType: // value for 'requestType'
 *      cartType: // value for 'cartType'
 *      requestSource: // value for 'requestSource'
 *      addressBook: // value for 'addressBook'
 *   },
 * });
 */
export function useSoldToAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoldToAccountsQuery, SoldToAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<SoldToAccountsQuery, SoldToAccountsQueryVariables>(SoldToAccountsDocument, options);
      }
export function useSoldToAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoldToAccountsQuery, SoldToAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<SoldToAccountsQuery, SoldToAccountsQueryVariables>(SoldToAccountsDocument, options);
        }
export type SoldToAccountsQueryHookResult = ReturnType<typeof useSoldToAccountsQuery>;
export type SoldToAccountsLazyQueryHookResult = ReturnType<typeof useSoldToAccountsLazyQuery>;
export type SoldToAccountsQueryResult = ApolloReactCommon.QueryResult<SoldToAccountsQuery, SoldToAccountsQueryVariables>;