// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CheckTermConditionQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.OmsGuestInput>;
}>;


export type CheckTermConditionQuery = { __typename?: 'Query', checkTermCondition?: { __typename?: 'TermConditionResponse', requireTermConditionUpdate: boolean, terms: Array<{ __typename?: 'termConditionItem', termUrl: string, termKey: string, agree: boolean } | null> } | null };


export const CheckTermConditionDocument = gql`
    query CheckTermCondition($input: OmsGuestInput) {
  checkTermCondition(input: $input) {
    terms {
      termUrl
      termKey
      agree
    }
    requireTermConditionUpdate
  }
}
    `;
export type CheckTermConditionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckTermConditionQuery, CheckTermConditionQueryVariables>, 'query'>;

    export const CheckTermConditionComponent = (props: CheckTermConditionComponentProps) => (
      <ApolloReactComponents.Query<CheckTermConditionQuery, CheckTermConditionQueryVariables> query={CheckTermConditionDocument} {...props} />
    );
    

/**
 * __useCheckTermConditionQuery__
 *
 * To run a query within a React component, call `useCheckTermConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTermConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTermConditionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckTermConditionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckTermConditionQuery, CheckTermConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckTermConditionQuery, CheckTermConditionQueryVariables>(CheckTermConditionDocument, options);
      }
export function useCheckTermConditionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckTermConditionQuery, CheckTermConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckTermConditionQuery, CheckTermConditionQueryVariables>(CheckTermConditionDocument, options);
        }
export type CheckTermConditionQueryHookResult = ReturnType<typeof useCheckTermConditionQuery>;
export type CheckTermConditionLazyQueryHookResult = ReturnType<typeof useCheckTermConditionLazyQuery>;
export type CheckTermConditionQueryResult = ApolloReactCommon.QueryResult<CheckTermConditionQuery, CheckTermConditionQueryVariables>;