import { CheckTermConditionDocument } from '@src/queries/TermConditionQuery.generated'

const fetchCheckTermCondition = async (client, setTerms, setTcUpdateOpen) => {
  let checkedTermCondition = true
  try {
    const { data } = await client.query({
      query: CheckTermConditionDocument,
      fetchPolicy: 'no-cache',
    })
    if (
      data &&
      data.checkTermCondition &&
      data.checkTermCondition.requireTermConditionUpdate
    ) {
      setTerms(data.checkTermCondition.terms)
      setTcUpdateOpen(true)
      checkedTermCondition = false
    } else {
      checkedTermCondition = true
    }
  } catch (err) {
    setTcUpdateOpen(false)
    checkedTermCondition = false
  }
  return checkedTermCondition
}
export const checkTermCondition = async (client, setTerms, setTcUpdateOpen) => {
  return fetchCheckTermCondition(client, setTerms, setTcUpdateOpen)
}
