import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useApolloClient } from 'react-apollo'
import { CurrentUserDocument } from '@src/queries/CurrentUserQuery.generated'
import { LoggedInUser } from '@src/types/graphql-types'
import { sendLoginEvent, setLastReferrerUrl } from '@utils/analytics'
import { sendSuccessfulLoginEventForTealium } from '@utils/tealiumAnalytics'
import {
  cartRoute,
  CartType,
  CartView,
  homeRoute,
  useRouter,
} from '@src/routes'
import messages from '@utils/messages'
import { useUserSession } from '@utils/useUserSession'
import { useCookies, AppCookies } from '@utils/cookies'
import { useChinaUser } from '@src/utils/useChinaUser'
import { checkTermCondition } from '@src/utils/tcUpdateUtils'
import { sessionStorage } from 'react-storage'
import { SoldToAccountsDocument } from '@src/queries/SoldToAccountsQuery.generated'
import {
  getCartMiniAction,
  getCartTypeParam,
  isRedOrBlueFTBCart,
} from '@utils/cartUtils'
import { QUICK_CART } from '@src/queries/QuickCartQuery'
import getConfig from 'next/config'

export const LOGIN_REDIRECT = 'LOGIN_REDIRECT'

const useLoginRedirect = (
  submitSuccess,
  setLoginError,
  gaIdPrefix,
  agreeClicked,
  setTerms,
  setTcUpdateOpen,
  useradLink = ''
) => {
  const router = useRouter()
  const intl = useIntl()
  const { userSession, userSessionActions } = useUserSession()
  const client = useApolloClient()
  const [cookies, setCookies] = useCookies(['_ga'])
  const isChinaUser = useChinaUser()
  const {
    publicRuntimeConfig: {
      sso,
      featureFlags: { ssoEnabled },
    },
  } = getConfig()

  const loginRedirect = async () => {
    try {
      const { data } = await client.query({
        query: CurrentUserDocument,
      })
      const currentUser = data?.me as LoggedInUser
      const websiteLocale =
        currentUser?.participantSitePreference?.websiteLocale?.split('_')
      const country =
        currentUser?.contactAddress?.country || userSession.country
      const isPurchasingAgent = currentUser?.roles?.isPurchasingAgent
      const uniqueSoldTos = [...new Set(currentUser?.soldToPartners)].length
      const hasUniqueSoldTos = uniqueSoldTos > 1
      const isCustomerServiceRep = currentUser?.roles?.isCustomerServiceRep
      if (gaIdPrefix) {
        setLastReferrerUrl()
        sendSuccessfulLoginEventForTealium({
          gaId: `${gaIdPrefix} > ${cookies._ga}`,
          me: currentUser,
          isOffersPromotionsEmail:
            currentUser?.participantCommunicationSetting
              ?.isOffersPromotionsEmail,
          roleNames: currentUser?.roleNames,
          currentUser,
          emproveUserType: currentUser?.metadata?.emproveDomainStatus,
          organizationType:
            currentUser.participantComplianceInformation?.organizationType,
          organizationPosition:
            currentUser.participantComplianceInformation?.position,
          organizationWebsite:
            currentUser.participantComplianceInformation?.organizationWebsite,
          primaryBusinessActivity:
            currentUser.participantComplianceInformation
              ?.primaryBusinessActivity,
          isB2BUser: currentUser?.profileType === 'B2B',
        })
      }

      // Prefer user's locale from profile, fallback to userSession
      const language =
        websiteLocale && websiteLocale.length > 0
          ? websiteLocale[0]
          : userSession.language

      userSessionActions.setLanguage(language)
      userSessionActions.setCountry(country)

      /* cant use router.push here since we already have a base path of /{country}/{lang}
        and router.query.redirect already captures the base path, so pushing results in
        /US/en/US/en/....rest of path
      */

      const loginRedirect = sessionStorage.getItem<string>(LOGIN_REDIRECT)
      let href = homeRoute.index()
      if (!!router.query?.redirect || loginRedirect) {
        href = loginRedirect ? loginRedirect : router.query.redirect
        const cartType = new URLSearchParams(href).get('type') as CartType
        // If redirecting to checkout, check if CSR user
        if (href.includes(cartRoute[CartView.Checkout](cartType))) {
          const isBlueErpIntegrationEnabled =
            !!currentUser?.metadata?.isBlueErpIntegrationEnabled
          if (
            isPurchasingAgent &&
            !hasUniqueSoldTos &&
            !isBlueErpIntegrationEnabled
          ) {
            href = cartRoute[CartView.SelectAddresses](cartType)
          } else if (
            ((isPurchasingAgent && hasUniqueSoldTos) || uniqueSoldTos > 1) &&
            !isBlueErpIntegrationEnabled
          ) {
            href = cartRoute[CartView.SelectAccount](cartType)
          } else if (isCustomerServiceRep) {
            href = cartRoute[CartView.CustomerSearch](cartType)
          } else if (isBlueErpIntegrationEnabled) {
            const { data: quickCartData } = await client.query({
              query: QUICK_CART,
              variables: { id: getCartMiniAction(cartType) },
              context: { userSession },
            })
            const isFtbCart = isRedOrBlueFTBCart(
              quickCartData?.getCart?.cartType
            )
            if (!isFtbCart) {
              const { data: accountsData } = await client.query({
                query: SoldToAccountsDocument,
                variables: {
                  cartType: getCartTypeParam(
                    isBlueErpIntegrationEnabled,
                    cartType
                  ),
                },
                context: { userSession },
              })
              const accountNum = accountsData?.getSoldToAccounts?.length || 0
              if (isPurchasingAgent) {
                if (accountNum > 1) {
                  href = cartRoute[CartView.SelectAccount](cartType)
                } else if (accountNum === 1) {
                  href = cartRoute[CartView.SelectAddresses](cartType)
                } else {
                  href = cartRoute[CartView.Checkout](cartType)
                }
              } else {
                if (accountNum > 1) {
                  href = cartRoute[CartView.SelectAccount](cartType)
                } else {
                  href = cartRoute[CartView.Checkout](cartType)
                }
              }
            }
          }
        }
      }
      if (ssoEnabled && useradLink && sso) {
        const { scheme, host } = sso
        const azureLoginURI = `${scheme}://${host}/auth/v1/oauth2/authorize?redirect_uri=${window.location.origin}/auth/openid/return`

        const date = new Date()
        date.setTime(date.getTime() + 120 * 1000)
        setCookies(AppCookies.SSORedirectCookie, href, { expires: date })

        window.location.href = azureLoginURI
      } else {
        window.location.href = href
      }

      sessionStorage.removeItem<string>(LOGIN_REDIRECT)
      if (gaIdPrefix) {
        sendLoginEvent({
          me: currentUser,
          isOffersPromotionsEmail:
            currentUser?.participantCommunicationSetting
              ?.isOffersPromotionsEmail,
        })
      }
    } catch (error) {
      const message = intl.formatMessage(messages.USER_UNABLE_TO_LOGIN)
      setLoginError && setLoginError(message)
    }
  }
  const handleRedirect = async () => {
    if (isChinaUser) {
      // for TCUpdateDialog
      if (agreeClicked === null) {
        return
      }
      if (!agreeClicked) {
        const checkedTermCondition = await checkTermCondition(
          client,
          setTerms,
          setTcUpdateOpen
        )
        if (checkedTermCondition) {
          loginRedirect()
        }
      } else {
        loginRedirect()
      }
      return
    }
    loginRedirect()
  }
  useEffect(() => {
    if (submitSuccess && userSession.accessToken) {
      handleRedirect()
    }
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitSuccess, userSession.accessToken, agreeClicked, useradLink])
}

export default useLoginRedirect
